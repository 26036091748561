const firebaseConfig = {
  apiKey: "AIzaSyCI1Hb4wMvlL56WStFECeBDw5fxVugVUyc",
  authDomain: "cdm-2018-f941f.firebaseapp.com",
  databaseURL: "https://cdm-2018-f941f.firebaseio.com",
  projectId: "cdm-2018-f941f",
  storageBucket: "cdm-2018-f941f.appspot.com",
  messagingSenderId: "832080279207",
  appId: "1:832080279207:web:814f698062b6e52c7dcd82"
};

export default firebaseConfig;