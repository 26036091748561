import React from "react";
import firebase from "../../firebase";
import MatchNoteLine from "./MatchNoteLine";
import LargeHeader from "../Header/LargeHeader";
import {
  personCircleOutline,
} from "ionicons/icons";
import {
  IonList,
  IonBadge,
  IonItem,
  IonLabel,
  IonText,
  IonIcon

} from "@ionic/react";
const MatchNotes = ({lineup, visitorcolor, localcolor}) => {

  return (
    <>        
    <IonList lines="none">

      {Object.keys(lineup)
      //.filter((d) => match.lineup[d].team_id === (voteFor ? match.localteam_id : match.visitorteam_id) )
      .filter((d) => lineup[d].averageRating )
      .sort((a, b) => lineup[a].averageRating < lineup[b].averageRating ? 1 : -1)
      .map((player, index) => {

        player = lineup[player];

        return <IonItem key={index}>
          <MatchNoteLine visitorcolor={visitorcolor} localcolor={localcolor} player={player} />
        </IonItem>
      })}
      </IonList>

    </>
  );
};

export default MatchNotes;
