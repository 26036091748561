import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import SmallHeader from "../../components/Header/SmallHeader";
import LargeHeader from "../../components/Header/LargeHeader";
import MatchList from "../../components/Match/MatchList";

const Notes = (props) => {
  return (
    <IonPage>
      <SmallHeader title="Les notes" />
      <IonContent fullscreen>
        <LargeHeader title="Les notes" />
        <MatchList type={"notes"} location={props.location} />
      </IonContent>
    </IonPage>
  );
};

export default Notes;
