import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonIcon,
} from "@ionic/react";

const MatchHeader = ({ title, team, actionVoteFor, showVote, actionVote }) => {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton onClick={() => actionVoteFor()}>Voter pour {team}</IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton 
            style={{
                width: "150px",
                //fontSize: "0.8rem",
                fontWeight: "normal",
              }} 
            fill="outline" 
            onClick={() => actionVote()}>{showVote ? "Voir les votes" : "Voter"}</IonButton>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default MatchHeader;
