import React from "react";
import firebase from "../../firebase";
import MatchItem from "./MatchItem";
import LargeHeader from "../Header/LargeHeader";

import {
  IonList
} from "@ionic/react";
const MatchList = ({type}) => {
  const [links, setLinks] = React.useState([]);
  //const isTrending = props.location.pathname.includes("notes");

  React.useEffect(() => {
    const unsubscribe = getLinks();
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  function getLinks() {
    /* if (isTrending) {
      return firebase.db
        .collection("links")
        .orderBy("voteCount", "desc")
        .onSnapshot(handleSnapshot);
    } */
    console.log(Date.now())

    let ref = firebase.db
    .collection("matchs")
    //.orderBy("created", "desc")
    .where('time.starting_at.timestamp', '>', Date.now() / 1000)
    .onSnapshot(handleSnapshot);


    if(type === "notes") {
      
      ref = firebase.db
    .collection("matchs")
    //.orderBy("created", "desc")
    .where('time.starting_at.timestamp', '<', Date.now() / 1000)
    .onSnapshot(handleSnapshot)}

    return ref;
  }

  function handleSnapshot(snapshot) {
    const links = snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    setLinks(links);
  }

  return (
    <>        
    <IonList lines="none">

      {links.map((link, index) => (
        <MatchItem
          bg={index %2}
          key={link.id}
          showNotes={type === "notes"}
          url={`/match/${link.id}`}
          link={link}
        />
      ))}
      </IonList>

    </>
  );
};

export default MatchList;
