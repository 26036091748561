
import React from "react";
import firebase from "../../firebase";
import MatchItem from "./MatchItem";
import LargeHeader from "../Header/LargeHeader";
import {
  personCircleOutline,
} from "ionicons/icons";
import {
  IonList,
  IonBadge,
  IonItem,
  IonLabel,
  IonText,
  IonIcon

} from "@ionic/react";
const MatchNoteLine = ({player, visitorcolor, localcolor}) => {

  const elementRef = React.useRef();

  React.useEffect(() => {
    console.log(visitorcolor, localcolor, player)
    const divElement = elementRef.current;
    divElement.style.setProperty('--background', player.team_local ?  visitorcolor : localcolor);
  }, [visitorcolor, localcolor, player]);

  function voteToColor(vote) {
    if(vote < 1) return "0";
    else if(vote < 2) return "1";
    else if(vote < 3) return "2";
    else if(vote < 4) return "3";
    else if(vote < 5) return "4";
    else if(vote < 6) return "5";
    else if(vote < 7) return "6";
    else if(vote < 8) return "7";
    else if(vote < 9) return "8";
    else if(vote < 10) return "9";
    else if(vote === 10) return "10";
  }

  return (
    <>        
    <IonBadge ref={elementRef} slot="start">{player.position}</IonBadge>
                <IonLabel>{player.player_name}</IonLabel>           
 
                <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.totalUsersCount} 
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>
                
                {player.averageRating ? <IonBadge color={voteToColor(player.averageRating)}>{player.averageRating.toFixed(1)}</IonBadge> : <IonBadge color={"light"}>-</IonBadge>}

    </>
  );
};

export default MatchNoteLine;
