import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonBadge,
  IonIcon,
  IonText
} from "@ionic/react";

import {
  personCircleOutline,
} from "ionicons/icons";

const PlayerModal = ({ isOpen, title, sendAction, closeAction, comment, data }) => {

  const [commentText, setCommentText] = React.useState(
    comment ? comment.text : ""
  );

  function handleSendAction(item) {
    sendAction(item);
    setCommentText("");
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={closeAction}>
      <IonHeader translucent>
        <IonToolbar color="primary">
          <IonTitle>{data.player_name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={closeAction}>Close</IonButton>
          </IonButtons>
          {/* <IonButtons slot="end">
            <IonButton onClick={() => handleSendAction(commentText)}>
              Send
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonList lines="none">

      {data.totalUsersCount} votes

        {data && data.totalNotes && Object.keys(data.totalNotes).map((note, index) => (
          <IonItem key={index} className="ion-text-center">

            
            <IonBadge color={note} slot="start">{note}</IonBadge>
            
            <IonProgressBar slot="end" color="primary" value={(data.totalNotes[note] / data.totalUsersCount)}></IonProgressBar>
            
            <IonText slot="end" style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {data.totalNotes[note]}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>
          </IonItem>
        ))}
      </IonList>
      </IonContent>
      {/* <CommentModal
          isOpen={showModal}
          title="New Comment"
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        /> */}
    </IonModal>
    
  );
};

export default PlayerModal;
