import React from "react";
import {
  IonAvatar,
  IonLabel,
  IonIcon,
  IonText,
  IonItem,
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  chevronUpCircleOutline,
  personCircleOutline,
  timeOutline,
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import MatchNotes from "./MatchNotes";

const MatchItem = ({ link, index, showNotes, url, browser, bg }) => {
  return (

        <>
          <IonItem color={link.league_id} className="ion-text-center" routerLink={url} onClick={browser} button>
            <IonAvatar slot="start">
              <img alt="o" src={link.localTeam.data.logo_path} />
            </IonAvatar>
            
            <IonLabel>
              <p
                style={{
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                }}
              >
                <IonIcon
                  //icon={linkOutline}
                  img={link.league.data.logo_path}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {link.league.data.name} - {link.round.data.name}éme journée 
                </IonText>
              </p>

              <div className=" ion-text-wrap">
                <strong style={{ fontSize: "1rem" }}>{link.localTeam.data.name} {link.time.status != "NS" && link.scores.localteam_score} - {link.time.status != "NS" && link.scores.visitorteam_score} {link.visitorTeam.data.name}
                 </strong>
              </div>


              <p
                style={{
                  alignItems: "center",
                  fontSize: "0.8 rem",
                  fontWeight: "normal",
                }}
              >
                <IonIcon
                  icon={chevronUpCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {link.voteCount} upvotes             
                  {/* element to be queried */}
                </IonText>
                {" | "}
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {link.time.status}
                </IonText>
                {" | "}
                <IonIcon
                  icon={timeOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {
                    formatDistanceToNow(new Date (link.time.starting_at.date_time
                    //.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })
                    ))
                    } 

                    {link.minute}
                </IonText>
                
                {/* {link.comments.length > 0 && (
                  <>
                    {" | "}
                    <IonIcon
                      icon={chatbubbleEllipsesOutline}
                      style={{
                        verticalAlign: "middle",
                      }}
                    />{" "}
                    <IonText
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {link.comments.length} comments
                    </IonText>
                  </>
                )}{" "} */}
              </p>
            </IonLabel>

            <IonAvatar slot="end">
              <img alt="o" src={link.visitorTeam.data.logo_path} />
            </IonAvatar>
          </IonItem>

          {showNotes && <MatchNotes localcolor={link.colors?.localteam?.color} visitorcolor={link.colors?.visitorteam?.color} lineup={link.lineup} />}
{/* 
          <IonAccordionGroup>
      <IonAccordion value="first">
        <IonItem slot="header" color="light">
          <IonLabel>First Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          First Content
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem slot="header" color="light">
          <IonLabel>Second Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Second Content
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem slot="header" color="light">
          <IonLabel>Third Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Third Content
        </div>
      </IonAccordion>
    </IonAccordionGroup> */}

        </>

  );
};

export default MatchItem;
