import React from "react";
import firebase from "../firebase";
import { Plugins } from "@capacitor/core";
import UserContext from "../contexts/UserContext";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem, IonAvatar,
  IonLabel, IonList, IonSelect, IonSelectOption, IonBadge, IonNote, IonText, IonIcon

} from "@ionic/react";
import {
  personCircleOutline,
  logoTwitter
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import NavHeader from "../components/Header/NavHeader";
import MatchHeader from "../components/Header/MatchHeader";

import { trashOutline } from "ionicons/icons";
import LinkItem from "../components/Link/LinkItem";
import CommentModal from "../components/Match/CommentModal";
import PlayerModal from "../components/Match/PlayerModal";


import LinkComment from "../components/Link/LinkComment";
import MatchItem from "../components/Match/MatchItem";

const { Browser } = Plugins;

const Match = (props) => {
  const { user } = React.useContext(UserContext);
  const [match, setMatch] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [dataModal, setDataModal] = React.useState(false);
  const [voteFor, setVoteFor] = React.useState(true);
  const [showVote, setShowVote] = React.useState(true);
  const [myVote, setMyVote] = React.useState([]);
  
  const linkId = props.match.params.linkId;
  //console.log(linkId)
  const linkRef = firebase.db.collection("matchs").doc('m_' + linkId);



  /* React.useEffect(() => {
    //Object.keys(link.lineup)
    // eslint-disable-next-line
  }, [linkRef]); */

  React.useEffect(() => {
    getMatch();
    // eslint-disable-next-line
  }, [linkId]);

  React.useEffect(() => {
    user && user.uid && getNotes();
    // eslint-disable-next-line
    //console.log(new Date(Date.now()))
  }, [user]);

  function getMatch() {
    /* linkRef.get().then((doc) => {
      setLink({ ...doc.data(), id: doc.id });
    }); */

    linkRef.onSnapshot(function(doc) {
      //if(doc.docChanges().length > 0){
        setMatch({ ...doc.data(), id: doc.id });
        console.log(doc.data())
    if(doc.data().colors && doc.data().colors.visitorteam) document.documentElement.style.setProperty("--backgroundVisitorTeam", doc.data().colors.visitorteam.color);
    if(doc.data().colors && doc.data().colors.localteam) document.documentElement.style.setProperty("--backgroundLocalTeam", doc.data().colors.localteam.color);

        
      //(this.el.querySelector('ion-toolbar') as any).style.setProperty('--color', theme.color);
      //}
    })
  }

  function getNotes() {

    firebase.db.collection("notes")
    .where('match_id', '==', 'm_' + linkId)
    .where('user_id', '==', user.uid)
    .onSnapshot(handleSnapshot)
  }

  function handleSnapshot(snapshot) {
    let data = {};
    snapshot.docs.map((doc) => {
      //return { id: doc.id, ...doc.data() };
      data[doc.data().player_id] = doc.data();
    });
    console.log("setMyVote", data)
    setMyVote(data);
  }

  function handleVoteFor() {
    setVoteFor(!voteFor);
  }

  function handleShowVote() {  
    setShowVote(!showVote);
  }

  

  function handleOpenModal(player) {
    setShowModal(true);
    setDataModal(match.lineup[player])
    
  }
  
  function handleCloseModal() {
    setShowModal(false);
  }

  function handleAddComment(commentText) {
    if (!user) {
      props.history.push("/login");
    } else {
      linkRef.get().then((doc) => {
        if (doc.exists) {
          const previousComments = doc.data().comments;
          const newComment = {
            postedBy: { id: user.uid, name: user.displayName },
            created: Date.now(),
            text: commentText,
          };
          const updatedComments = [...previousComments, newComment];
          linkRef.update({ comments: updatedComments });
          setMatch((prevState) => ({
            ...prevState,
            comments: updatedComments,
          }));
        }
      });
      setShowModal(false);
    }
  }

  function handleAddVote() {
    if (!user) {
      props.history.push("/login");
    } else {
      linkRef.get().then((doc) => {
        if (doc.exists) {
          const previousVotes = doc.data().votes;
          const vote = {votedBy: { id: user.uid, name: user.displayName }} ; 
          

          // const upvoteButton = document.querySelector("#upvote");
          // const downvoteButton = document.querySelector("#downvote");
          // const score = document.querySelector(previousVotes);
          // const vote = type => {
          //   const buttons = { "1": upvoteButton, "-1": downvoteButton };
          //   const calvote = Number(score.textContent);
          
          //   if (buttons[type].classList.contains("active")) {
          //     score.textContent = calvote - type;
          //     buttons[type].classList.remove("active");
          //   } else if (buttons[-type].classList.contains("active")) {
          //     score.textContent = calvote + 2 * type;
          //     buttons[-type].classList.remove("active");
          //     buttons[type].classList.add("active");
          //   } else {
          //     score.textContent = calvote + type;
          //     buttons[type].classList.add("active");
          //   }
          // };
          
          // upvoteButton.addEventListener("click", () => vote(1));
          // downvoteButton.addEventListener("click", () => vote(-1));
        
         
          const updatedVotes = [...previousVotes, vote];
          const voteCount = updatedVotes.length;

          linkRef.update({ votes: updatedVotes, voteCount });

          setMatch((prevState) => ({
            ...prevState,
            votes: updatedVotes,
            voteCount: voteCount,
          }));


        }
      });
    }
  }

  function handleDeleteLink() {
    linkRef
      .delete()
      .then(() => {
        console.log(`Document with ID ${match.id} deleted`);
      })
      .catch((err) => {
        console.error("Error deleting document", err);
      });
    props.history.push("/");
  }

  const handleVote = (vote) => {
    //console.log(vote.target.id, vote.detail.value)

    if (!user) {
      props.history.push("/login");
    } else {
      const ref = firebase.db.collection('matchs').doc(match.id);

      //const votes = firebase.db.collection('matchs').doc(match.id).collection('votes').doc('London').set({ population: 1 });;
      //const notes = 
      firebase.db.collection('notes').add({ 
        match_id: match.id, 
        player_id: vote.target.id, 
        player_name: match.lineup[vote.target.id].player_name,
        position: match.lineup[vote.target.id].position,
        match: match.localTeam.data.name + " " + match.scores.localteam_score + " - " + match.scores.visitorteam_score + " " + match.visitorTeam.data.name,
        note: vote.detail.value, 
        user_id: user.uid, 
        username: user.displayName, 
        created: Date.now(), 
      });

      firebase.db
      .runTransaction(async transaction => {
        const doc = await transaction.get(ref);

        // if it does not exist set the population to one
        /* if (!doc.exists) {
          transaction.set(ref, { population: 1 });
          // return the new value so we know what the new population is
          return 1;
        } */

        // exists already so lets increment it + 1

        const totalRating = doc.data().lineup[vote.target.id].totalRating || 0;
        const totalUsersCount = doc.data().lineup[vote.target.id].totalUsersCount || 0;

        let data = {};
        data.lineup = {};



        
        
        const newTotalRating = totalRating + Number(vote.detail.value);
        const newTotalUsersCount = totalUsersCount + 1;  

        data.lineup[vote.target.id] = {
          //totalComments : doc.data().totalComments + 1,
          totalRating : newTotalRating,
          totalUsersCount : newTotalUsersCount,
          averageRating : newTotalRating/newTotalUsersCount,
        };

        if(totalRating === 0){
          
          data.lineup[vote.target.id].totalNotes = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0};
          data.lineup[vote.target.id].totalNotes[vote.detail.value] = 1;

        } else {

          data.lineup[vote.target.id].totalNotes = {};
          data.lineup[vote.target.id].totalNotes[vote.detail.value] = doc.data().lineup[vote.target.id].totalNotes[vote.detail.value] + 1;
  
        }


        
      /*  data = {'lineup': {'p_118126': {
          //totalComments : doc.data().totalComments + 1,
          totalRating : newPopulationTotalRating,
          totalUsersCount : newTotalUsersCount,
          averageRating : newPopulationTotalRating/newTotalUsersCount,
        }}} */

        transaction.set(ref, data, {merge: true});

        // return the new value so we know what the new population is
        return {note: vote.target.id, value: vote.detail.value};
      })
      .then(vote => {

        myVote[vote.note]={note: vote.value};

        setMyVote(myVote)
        console.log('myVote', myVote);
      })
      .catch(error => {
        console.log('Transaction failed: ', error);
      });
    }
  };

  function postedByAuthUser(link) {
    return user && user.uid === link.id;
  }

  async function openBrowser() {
    await Browser.open({
      url: match.url,
    });
  }

  async function shareOnTwitter() {
    let text = "Mes notes\n";
    console.log(myVote)
    console.log(Date.now()/1000, match.time.starting_at.timestamp + 3600 * 12)

    Object.keys(myVote)
      .filter((d) => match.lineup[d].team_id === (voteFor ? match.localteam_id : match.visitorteam_id) )
      .sort((a, b) => match.lineup[a].formation_position > match.lineup[b].formation_position ? 1 : -1)
      .map((player, index) => {
        player = match.lineup[player]

        text += player.player_name + ": " + myVote[player.player_id].note + "\n";


        console.log(player.player_id, player)
      })

    await Browser.open({
      url: "https://twitter.com/intent/tweet?text="+encodeURI(text)+"&hashtags="+match.localTeam.data.short_code+match.visitorTeam.data.short_code,
    });
  }

  function voteToColor(vote) {
    if(vote < 1) return "0";
    else if(vote < 2) return "1";
    else if(vote < 3) return "2";
    else if(vote < 4) return "3";
    else if(vote < 5) return "4";
    else if(vote < 6) return "5";
    else if(vote < 7) return "6";
    else if(vote < 8) return "7";
    else if(vote < 9) return "8";
    else if(vote < 10) return "9";
    else if(vote === 10) return "10";
  }

  

  return (
    <IonPage>
      {match && <MatchHeader
        title={""}
         
        option={match && postedByAuthUser(match)}
        actionVoteFor={handleVoteFor}
        showVote={showVote}
        actionVote={handleShowVote}
        team={voteFor ? match.visitorTeam.data.name : match.localTeam.data.name}
      />}
      <IonContent>
      {
                    formatDistanceToNow(Date.now()+ 1000 * 60 * 60 * 12)

                    //(h*60*60*1000)
                    
                    }

                   

                    

      {match && Date.now()/1000 > match.time.starting_at.timestamp ? "commencé" : "pas commencé"}
      {match && Date.now()/1000 > match.time.starting_at.timestamp + 3600 * 12 ? "pas terminé" : "terminé"}
      {match && <IonList lines="none">        
      <MatchItem
          showNotes={false}
          url={`/match/${match.id}`}
          link={match}

        /></IonList>}
      <IonList>
      {match && match.lineup && Object.keys(match.lineup)
      .filter((d) => match.lineup[d].team_id === (voteFor ? match.localteam_id : match.visitorteam_id) )
      .sort((a, b) => match.lineup[a].formation_position > match.lineup[b].formation_position ? 1 : -1)
      .map((player, index) => {
        var DoShowVote = showVote;

        player = match.lineup[player]
        const customAlertOptions = {
          header: player.player_name,
          subHeader: 'Select your favorite topping',
          message: 'Choose only one',
          translucent: false
        };


        return (
              <IonItem key={index} button={!DoShowVote || myVote[player.player_id]} onClick={() => (!DoShowVote || myVote[player.player_id]) && handleOpenModal(player.player_id)}>
               <IonBadge 
                //color={match.colors.visitorTeam.color} 
                color={voteFor ? "visitor-team" : "local-team"}
                style={{
                  '--background': '#ff0000 !important',//match.colors.visitorTeam.color,
                }}
                slot="start">{player.position}</IonBadge>
                {/* <IonAvatar slot="start">
                  <img alt="o" src={"https://cdn.sportmonks.com/images/soccer/players/8/"+player.player_id+".png"} />
                </IonAvatar> */}
                <IonLabel>{player.player_name}</IonLabel>
                           
 
                {(!DoShowVote || myVote[player.player_id]) && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.totalUsersCount || 0} 
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}
                

                {(!DoShowVote || myVote[player.player_id]) && player.averageRating && <IonBadge color={voteToColor(player.averageRating)}>{player.averageRating.toFixed(1)}</IonBadge>}
                {(!DoShowVote || myVote[player.player_id]) && !player.averageRating && <IonBadge color={"light"}>-</IonBadge>}


                {(DoShowVote && !myVote[player.player_id]) && <IonSelect mode="ios" id={player.player_id} interfaceOptions={customAlertOptions} interface="action-sheet" placeholder="Voter"
                onIonChange={(e) => handleVote(e)}
                >
                  <IonSelectOption value="0">0</IonSelectOption>
                  <IonSelectOption value="1">1</IonSelectOption>
                  <IonSelectOption value="2">2</IonSelectOption>
                  <IonSelectOption value="3">3</IonSelectOption>
                  <IonSelectOption value="4">4</IonSelectOption>
                  <IonSelectOption value="5">5</IonSelectOption>
                  <IonSelectOption value="6">6</IonSelectOption>
                  <IonSelectOption value="7">7</IonSelectOption>
                  <IonSelectOption value="8">8</IonSelectOption>
                  <IonSelectOption value="9">9</IonSelectOption>
                  <IonSelectOption value="10">10</IonSelectOption>
                </IonSelect>}
              </IonItem>
            )})}
     
            <IonButton
                  onClick={() => shareOnTwitter()}
                  fill="outline"
                  color="twitter"
                >
                <IonIcon color="twitter" icon={logoTwitter} slot="end"></IonIcon>
                  Partager sur Twitter
                </IonButton>
     
    </IonList>
        {/* <CommentModal
          isOpen={showModal}
          title="New Comment"
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        /> */}
        <PlayerModal
          isOpen={showModal}
          data={dataModal}
          //title={"Player"}
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        />
        {match && (
          <>
            <IonGrid>
              <IonRow>
                <IonCol class="ion-text-center">
                  {/* <LinkItem link={link} browser={openBrowser} /> */}
                  {/* <IonButton onClick={() => handleAddVote()} size="small" id="upvote">
                    Upvote
                  </IonButton> */}
                  {/* <IonButton onClick={() => handleAddVote()} size="small" id="downvote">
                    Downvote
                  </IonButton> */}
                  {/* <IonButton onClick={() => handleOpenModal()} size="small">
                    Comment
                  </IonButton> */}

                  {/* <IonButton onClick={() => handleVoteFor()} size="big">
                    Vote for
                  </IonButton>

                  Tu n'as pas vu le match?
                  <IonButton onClick={() => handleShowVote()} size="big">
                   Voir les votes
                    
                    
                  </IonButton> */}
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* {link.comments.map((comment, index) => (
              <LinkComment
                key={index}
                comment={comment}
                link={link}
                setLink={setLink}
              />
            ))} */}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Match;
